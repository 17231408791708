import React, { useRef, useEffect } from 'react';
import axios from 'axios';

// Configuration variables
const config = {
  fontSize: 12,           // Increase font size to reduce the number of characters drawn
  terrainScale: 0.1,      // Scale for the terrain features
  baseOpacity: 0.6,       // Base opacity for the characters
  opacityVariation: 0.1,  // Variation in opacity due to the terrain height
  contourLevels: 5,       // Number of contour levels
  waveAmplitude: 5,       // Amplitude for the wave effect
  waveFrequency: 0.1,     // Frequency for the wave effect
  rippleScale: 0.1,       // Scale for the ripple effect
  rippleIntensity: 0.3,   // Intensity of the ripple effect
  glimmerFrequency: 0.05, // Frequency for the glimmer effect
  glimmerAmplitude: 0,    // Amplitude for the glimmer effect
  rippleSpeed: 0.7,       // Speed of the ripple effect
};

const createPerlin = (seed) => {
  const permutation = [151, 160, 137, 91, 90, 15, 131, 13, 201, 95, 96, 53, 194, 233, 7, 225, 140, 36, 103, 30, 69, 142,
    8, 99, 37, 240, 21, 10, 23, 190, 6, 148, 247, 120, 234, 75, 0, 26, 197, 62, 94, 252, 219, 203, 117, 35, 11,
    32, 57, 177, 33, 88, 237, 149, 56, 87, 174, 20, 125, 136, 171, 168, 68, 175, 74, 165, 71, 134, 139, 48, 27,
    166, 77, 146, 158, 231, 83, 111, 229, 122, 60, 211, 133, 230, 220, 105, 92, 41, 55, 46, 245, 40, 244, 102,
    143, 54, 65, 25, 63, 161, 1, 216, 80, 73, 209, 76, 132, 187, 208, 89, 18, 169, 200, 196, 135, 130, 116, 188,
    159, 86, 164, 100, 109, 198, 173, 186, 3, 64, 52, 217, 226, 250, 124, 123, 5, 202, 38, 147, 118, 126, 255,
    82, 85, 212, 207, 206, 59, 227, 47, 16, 58, 17, 182, 189, 28, 42, 223, 183, 170, 213, 119, 248, 152, 2, 44,
    154, 163, 70, 221, 153, 101, 155, 167, 43, 172, 9, 129, 22, 39, 253, 19, 98, 108, 110, 79, 113, 224, 232,
    178, 185, 112, 104, 218, 246, 97, 228, 251, 34, 242, 193, 238, 210, 144, 12, 191, 179, 162, 241, 81,
    51, 145, 235, 249, 14, 239, 107, 49, 192, 214, 31, 181, 199, 106, 157, 184, 84, 204, 176, 115, 121, 50,
    45, 127, 4, 150, 254, 138, 236, 205, 93, 222, 114, 67, 29, 24, 72, 243, 141, 128, 195, 78, 66, 215, 61,
    156, 180];
  
  for (let i = 0; i < seed.length; i++) {
    permutation[i] = seed[i % seed.length];
  }

  const p = new Array(512);
  for (let i = 0; i < 512; i++) {
    p[i] = permutation[i % 256];
  }

  function fade(t) {
    return t * t * t * (t * (t * 6 - 15) + 10);
  }

  function lerp(t, a, b) {
    return a + t * (b - a);
  }

  function grad(hash, x, y, z) {
    const h = hash & 15;
    const u = h < 8 ? x : y;
    const v = h < 4 ? y : h === 12 || h === 14 ? x : z;
    return ((h & 1) === 0 ? u : -u) + ((h & 2) === 0 ? v : -v);
  }

  return function (x, y, z) {
    const X = Math.floor(x) & 255;
    const Y = Math.floor(y) & 255;
    const Z = Math.floor(z) & 255;

    x -= Math.floor(x);
    y -= Math.floor(y);
    z -= Math.floor(z);

    const u = fade(x);
    const v = fade(y);
    const w = fade(z);

    const A = p[X] + Y;
    const AA = p[A] + Z;
    const AB = p[A + 1] + Z;
    const B = p[X + 1] + Y;
    const BA = p[B] + Z;
    const BB = p[B + 1] + Z;

    return lerp(w, lerp(v, lerp(u, grad(p[AA], x, y, z), grad(p[BA], x - 1, y, z)),
      lerp(u, grad(p[AB], x, y - 1, z), grad(p[BB], x - 1, y - 1, z))),
      lerp(v, lerp(u, grad(p[AA + 1], x, y, z - 1), grad(p[BA + 1], x - 1, y, z - 1)),
        lerp(u, grad(p[AB + 1], x, y - 1, z - 1), grad(p[BB + 1], x - 1, y - 1, z - 1))));
  };
};

const AsciiBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = window.innerWidth;
    const height = window.innerHeight;
    canvas.width = width;
    canvas.height = height;

    ctx.font = `${config.fontSize}px monospace`;

    const getQuotes = async () => {
      try {
        const response = await axios.get('https://api.quotable.io/quotes?limit=3');
        const quotes = response.data.results.map(quote => quote.content);
        return quotes;
      } catch (error) {
        console.error("Error fetching quotes:", error);
        return ["Life is short", "Seize the day", "Keep it simple"];
      }
    };

    const drawTerrain = (quotes, noiseFunction, time) => {
      const rows = Math.floor(height / config.fontSize);
      const cols = Math.floor(width / config.fontSize);

      let charIndex = 0;
      const text = quotes.join(' '); // Combine all quotes into one string

      ctx.clearRect(0, 0, width, height); // Ensure the canvas is cleared before redrawing
      ctx.globalAlpha = 1.0; // Reset globalAlpha to 1.0

      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          const waveOffset = config.waveAmplitude * Math.sin(col * config.waveFrequency + row * config.waveFrequency + time);
          const heightValue = noiseFunction(
            (col + waveOffset) * config.terrainScale,
            (row + waveOffset) * config.terrainScale,
            0
          );

          const rippleValue = noiseFunction(
            col * config.rippleScale + time * config.rippleSpeed,
            row * config.rippleScale + time * config.rippleSpeed,
            1
          ) * config.rippleIntensity;

          const x = col * config.fontSize;
          const y = row * config.fontSize;

          const char = text[charIndex % text.length];
          charIndex++;

          const glimmerValue = Math.sin((col + row + time) * config.glimmerFrequency) * config.glimmerAmplitude;
          ctx.fillStyle = `rgba(255, 255, 255, ${config.baseOpacity + glimmerValue})`;

          for (let level = 0; level < config.contourLevels; level++) {
            if (heightValue + rippleValue > (level - 1) * 0.1 && heightValue + rippleValue <= level * 0.1) {
              ctx.globalAlpha = config.baseOpacity + config.opacityVariation * level + glimmerValue;
              ctx.fillText(char, x, y);
              break;
            }
          }
        }
      }
    };

    const drawLandscape = async () => {
      const quotes = await getQuotes();
      const randomSeed = new Uint8Array(256);
      window.crypto.getRandomValues(randomSeed); // Generate random seed
      const noiseFunction = createPerlin(randomSeed);

      let animationFrameId;

      const animate = (time) => {
        drawTerrain(quotes, noiseFunction, time * 0.001); // Convert time to seconds
        animationFrameId = requestAnimationFrame(animate);
      };

      animationFrameId = requestAnimationFrame(animate);

      setTimeout(() => {
        cancelAnimationFrame(animationFrameId);
      }, 3000);
    };

    drawLandscape();
  }, []);

  return <canvas ref={canvasRef} className="absolute inset-0 w-full h-full" />;
};

export default AsciiBackground;
