import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import AsciiBackground from './components/AsciiBackground.js';
import LocationDot from './components/LocationDot.js'

function App() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <AsciiBackground></AsciiBackground>
      <LocationDot></LocationDot>
    </div>
  );
}

export default App;
