import React, { useRef, useEffect } from 'react';

// Simple Perlin noise implementation
const generatePerlinNoise = (width, height) => {
  const noise = [];
  const perlin = [];
  let octaveCount = 3;
  let persistence = 0.5;

  // Generate white noise
  for (let i = 0; i < width * height; i++) {
    noise.push(Math.random());
  }

  // Generate Perlin noise
  for (let i = 0; i < width * height; i++) {
    let amplitude = 1;
    let totalAmplitude = 0;
    let noiseValue = 0;

    for (let octave = 0; octave < octaveCount; octave++) {
      const frequency = Math.pow(2, octave);
      amplitude *= persistence;
      totalAmplitude += amplitude;
      noiseValue += interpolateNoise(i % width, Math.floor(i / width), frequency, noise, width, height) * amplitude;
    }

    perlin.push(noiseValue / totalAmplitude);
  }

  return perlin;
};

const interpolateNoise = (x, y, frequency, noise, width, height) => {
  const sampleX = Math.floor(x / frequency) * frequency;
  const sampleY = Math.floor(y / frequency) * frequency;

  const sampleX1 = (sampleX + frequency) % width;
  const sampleY1 = (sampleY + frequency) % height;

  const horizontalBlend = (x - sampleX) / frequency;
  const verticalBlend = (y - sampleY) / frequency;

  const top = interpolate(noise[sampleY * width + sampleX], noise[sampleY * width + sampleX1], horizontalBlend);
  const bottom = interpolate(noise[sampleY1 * width + sampleX], noise[sampleY1 * width + sampleX1], verticalBlend);

  return interpolate(top, bottom, verticalBlend);
};

const interpolate = (a, b, blend) => {
  const theta = blend * Math.PI;
  const f = (1 - Math.cos(theta)) * 0.5;
  return a * (1 - f) + b * f;
};

const DotAnimation = () => {
  const canvasRef = useRef(null);
  const numDots = 500; // Reduced number of dots
  const dotRadius = 3;
  const hoverEffectRadius = 100;
  const hoverEffectStrength = 0.05;
  const words = ["welcome"];
  let currentWordIndex = 0;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = window.innerWidth;
    const height = window.innerHeight;
    const dotSpacing = 10;
    canvas.width = width;
    canvas.height = height;

    let hue = 0;
    let dots = [];
    let targetDots = [];

    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');
    offscreenCanvas.width = width;
    offscreenCanvas.height = height;

    const drawGridLines = () => {
      offscreenCtx.strokeStyle = 'rgba(255, 255, 255, 0.4)';
      offscreenCtx.lineWidth = 1;

      for (let x = 0; x < width; x += 50) {
        offscreenCtx.beginPath();
        offscreenCtx.moveTo(x, 0);
        offscreenCtx.lineTo(x, height);
        offscreenCtx.stroke();
      }

      for (let y = 0; y < height; y += 50) {
        offscreenCtx.beginPath();
        offscreenCtx.moveTo(0, y);
        offscreenCtx.lineTo(width, y);
        offscreenCtx.stroke();
      }
    };

    const generateTerrain = () => {
      const noise = generatePerlinNoise(width / 10, height / 10);

      offscreenCtx.strokeStyle = 'rgba(0, 0, 0, 0.2)';
      offscreenCtx.lineWidth = 2;

      for (let y = 0; y < height; y += 10) {
        offscreenCtx.beginPath();
        for (let x = 0; x < width; x += 10) {
          const value = noise[Math.floor(x / 10) + Math.floor(y / 10) * (width / 10)];
          offscreenCtx.lineTo(x, y + value * 50 - 25);
        }
        offscreenCtx.stroke();
      }
    };

    const initDots = () => {
      const clusters = [
        { x: width / 3, y: height / 2 },
        { x: (2 * width) / 3, y: height / 2 },
        { x: width / 2, y: height / 3 },
        { x: width / 2, y: (2 * height) / 3 },
      ];

      clusters.forEach(cluster => {
        for (let i = 0; i < numDots / clusters.length; i++) {
          const angle = Math.random() * 2 * Math.PI;
          const radius = Math.random() * 150;
          dots.push({
            x: cluster.x + Math.cos(angle) * radius,
            y: cluster.y + Math.sin(angle) * radius,
            targetX: cluster.x + Math.cos(angle) * radius,
            targetY: cluster.y + Math.sin(angle) * radius,
            visible: true,
          });
        }
      });
    };

    const createDotsFromText = (text) => {
      offscreenCtx.clearRect(0, 0, width, height);
      drawGridLines();
      generateTerrain();
      offscreenCtx.font = 'bold 150px sans-serif';
      offscreenCtx.textAlign = 'center';
      offscreenCtx.textBaseline = 'middle';
      offscreenCtx.fillStyle = 'black';

      offscreenCtx.fillText(text, width / 2, height / 2);

      const imageData = offscreenCtx.getImageData((width - 1000) / 2, (height - 200) / 2, 1000, 200);
      const data = imageData.data;
      const newDots = [];

      for (let y = 0; y < imageData.height; y += dotSpacing) {
        for (let x = 0; x < imageData.width; x += dotSpacing) {
          const index = (y * imageData.width + x) * 4;
          const alpha = data[index + 3];
          if (alpha > 128) {
            newDots.push({ x: x + (width - 1000) / 2, y: y + (height - 200) / 2 });
          }
        }
      }

      return newDots;
    };

    const updateDots = () => {
      targetDots = createDotsFromText(words[currentWordIndex]);
      currentWordIndex = (currentWordIndex + 1) % words.length;

      dots.forEach((dot, i) => {
        if (i < targetDots.length) {
          dot.targetX = targetDots[i].x;
          dot.targetY = targetDots[i].y;
          dot.visible = true;
        } else {
          dot.visible = false;
        }
      });
    };

    const animate = () => {
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(offscreenCanvas, 0, 0);
      hue += 1;

      dots.forEach(dot => {
        if (dot.visible) {
          dot.x += (dot.targetX - dot.x) * 0.05;
          dot.y += (dot.targetY - dot.y) * 0.05;

          const distanceToCenter = Math.sqrt((dot.x - width / 2) ** 2 + (dot.y - height / 2) ** 2);
          const colorOffset = (distanceToCenter / 10 + hue) % 360;

          ctx.beginPath();
          ctx.arc(dot.x, dot.y, dotRadius, 0, Math.PI * 2, false);
          ctx.fillStyle = `hsl(${colorOffset}, 100%, 50%)`;
          ctx.fill();
        }
      });

      requestAnimationFrame(animate);
    };

    const onMouseMove = (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      dots.forEach(dot => {
        const dx = dot.x - mouseX;
        const dy = dot.y - mouseY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < hoverEffectRadius) {
          const angle = Math.atan2(dy, dx);
          const force = (hoverEffectRadius - distance) * hoverEffectStrength;
          dot.targetX += Math.cos(angle) * force;
          dot.targetY += Math.sin(angle) * force;
        }
      });
    };

    initDots();
    updateDots();
    animate();
    setInterval(updateDots, 5000); // Update text every 5 seconds

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return <canvas ref={canvasRef} className="absolute inset-0 w-full h-full"></canvas>;
};

export default DotAnimation;
